export const CONTRACT_V1 = `<h1>Taste Network Participation Agreement</h1>
<p>
  This Taste Network Participation Agreement (the “<strong>Agreement</strong>”)
  is between Wombat Apps LLC d/b/a Carb Manager, a Hawaii limited liability
  company, with its principal place of business at 8201 164th Ave NE #200
  Redmond, WA 98052 (“<strong>Carb Manager</strong>”) and the company creating
  an account at tastenetwork.io (the “<strong>Company</strong>”). The Agreement
  will be effective on the day Company creates their account on tastenetwork.io,
  reviews, and agrees to the terms set forth in this Agreement (the “<strong
    >Effective Date</strong
  >”).
</p>
<ol type="1">
  <li>
    <p><strong>Purpose</strong></p>
  </li>
</ol>
<p>
  This Agreement sets forth general terms and conditions for the parties’
  activities in connection with the Carb Manager Taste Network Program, the
  details of which are set forth in Exhibit A (“<strong>Promotion Plan</strong
  >”).
</p>
<ol start="2" type="1">
  <li>
    <p><strong>Obligations</strong></p>
    <ol type="a">
      <li>
        <p>
          <strong>Carb Manager Obligations.</strong> Carb Manager will perform
          advertising, marketing and promotional activities as described in the
          Promotion Plan (“<strong>Promotion Activities</strong>”). As part of
          the Promotion Activities, Carb Manager may, in its sole discretion,
          provide names, contact information, and other personally identifiable
          information about Carb Manager customers
          (“<strong>Customers</strong>”) to Company (“<strong
            >Customer Information</strong
          >”). Carb Manager will ensure that any sharing of Customer Information
          complies with applicable privacy laws and that Customers have provided
          consent for such sharing.
        </p>
      </li>
      <li>
        <p>
          <strong>Company Obligations.</strong> Company will work in good faith
          with Carb Manager to identify Company food and beverage products to be
          included in the Taste Network Program (“<strong>Products</strong>”)
          and provide to Carb Manager complete and accurate information about
          all Products. Company will provide written content, visual, audio and
          other content or materials mutually agreed to by the parties for
          purposes of the Promotion Activities (“<strong
            >Promotion Materials</strong
          >”). Company agrees to deliver, in a timely manner, Products to
          Customers in accordance with the terms of the Taste Network Program.
          Company will use Customer Information only as permitted hereunder.
          Company shall comply with all policies applicable to the Taste Network
          Program, as may be adopted by Carb Manager from time to time and
          provided to Company with at least thirty (30) days’ notice.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><strong>Intellectual property</strong></p>
    <ol type="a">
      <li>
        <p>
          <strong>Trademarks. “Trademarks”</strong> means trademarks and service
          marks, trade dress, trade names, designs, slogans, domain names, logos
          and other business identifiers. Solely for the purpose of the
          Promotion Activities, Company hereby grants to Carb Manager a
          non-exclusive, nontransferable, limited license to use the Trademarks
          listed in the applicable Promotion Plan, including the right to
          sublicense the Trademarks solely to the extent necessary for the
          Promotion Activities (e.g., to website or other content publishers).
          To the extent provided to Carb Manager in writing in advance, Carb
          Manager will comply with Company’s reasonable branding requirements
          and restrictions for its Trademarks. Company shall have no right to
          market this relationship or use the Trademarks of Carb Manager without
          express, prior written consent. If Carb Manager approve such marketing
          by Company, then Carb Manager grants to Company a limited, revocable
          license to use the Carb Manager name solely for purposes of such
          approved publicity and solely for the duration of this Agreement or
          the approval of Carb Manager, whichever is shorter.
        </p>
      </li>
      <li>
        <p>
          <strong>Promotion Materials License.</strong> Company hereby grants to
          Carb Manager a nonexclusive, nontransferable, non-assignable, limited
          license to use the Promotion Materials solely for the purpose of
          performance of this Agreement including the right to sublicense the
          Trademarks solely to the extent necessary for the Promotion Activities
          (e.g., to website or other content publishers).
        </p>
      </li>
      <li>
        <p>
          <strong>Reservation of Rights.</strong> Except for any right expressly
          granted under this Agreement, each party reserves all rights. Other
          than those express grants, this Agreement does not grant any rights to
          either party’s Intellectual Property Rights, by implication, estoppel,
          or otherwise.
        </p>
      </li>
      <li>
        <p>
          <strong>Feedback.</strong> Any feedback or suggestions that Company
          provides to Carb Manager regarding its products or services is
          non-confidential and may be used by Carb Manager for any purpose
          without acknowledgement or compensation.
        </p>
      </li>
      <li>
        <p>
          <strong>User-generated Content.</strong> Any content generated about
          the Company or its products is owned by the creator of this content,
          whether that is Carb Manager or an individual Carb Manager Community
          Leader (“<strong>CMCL</strong>”) . Company may seek permission from
          any CMCL to utilize their content in Company’s own marketing or
          promotional campaigns.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><strong>Fees, expenses, and billing process</strong></p>
    <ol type="a">
      <li>
        <p>
          <strong>Fees.</strong> Company agrees to pay all fees associated with
          participation in Taste Network by the billing due date, and before any
          activities in the Promotion Plan are carried out by Carb Manager.
        </p>
      </li>
      <li>
        <p>
          <strong>Payment and Billing Process.</strong> Currently, all
          subscriptions are billed monthly or quarterly and must be paid before
          any services are rendered. Future payments will auto-renew on the same
          day of the month, depending on the plan chosen.
        </p>
        <ol type="1">
          <li>
            <p>
              <strong>Payment Failure:</strong> If payment is not received by
              Carb Manager by the final day of that month, all features under
              that plan are disabled and behind a paywall.
            </p>
          </li>
          <li>
            <p>
              <strong>Automatic Payments:</strong> All payments for Company’s
              selected subscription will be processed automatically on the same
              date as the original subscription was processed, after either 1
              month or 3 months, unless cancelled at least 24 hours prior to
              renewal date.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>Payment Methods</strong>. Carb Manager accepts payment by
          credit card and debit card. Additional payment methods may be added in
          the future.
        </p>
      </li>
      <li>
        <p>
          <strong>Late Payments.</strong> Late payments may result in suspension
          of service.
        </p>
      </li>
      <li>
        <p>
          <strong>Subscription Changes by Company.</strong> Companies may pause,
          change, or terminate their subscription at any time within their Taste
          Network account. However, changes or terminations will not take effect
          until the start of the next billing cycle. No refunds will be issued
          for the remainder of the current billing cycle.
        </p>
      </li>
      <li>
        <p>
          <strong>Pausing a Subscription.</strong> Company may pause their
          subscription for a reduced monthly fee, effective at the end of the
          month. While a subscription is paused, the Company may:
        </p>
        <ol type="1">
          <li><p>View data collected during previous months.</p></li>
          <li><p>Create or maintain promotions on the brand portal.</p></li>
        </ol>
      </li>
      <li>
        <p>
          <strong>Unused Services.</strong> All unused services will expire at
          the end of the billing cycle.
        </p>
      </li>
      <li>
        <p>
          <strong>Refunds.</strong> Refunds will be evaluated by a case by case
          basis.
        </p>
      </li>
      <li>
        <p>
          <strong>Expenses.</strong> Each party will pay for its own additional
          costs and expenses associated with this Agreement unless otherwise
          agreed in the applicable Promotion Plan.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><strong>Term and termination</strong></p>
    <ol type="a">
      <li>
        <p>
          <strong>Term.</strong> The term of this Agreement starts on the
          Effective Date and ends on the last day of the month during which
          Company cancels their subscription, unless terminated earlier by
          either party as set forth below.
        </p>
      </li>
      <li>
        <p><strong>Termination.</strong></p>
        <ol type="1">
          <li>
            <p>
              Carb Manager reserves the right to terminate or suspend service
              immediately, without prior notice, for any violation of these
              Terms, including but not limited to:
            </p>
              <p>Non-payment of fees,</p>
  <p>Violation of any applicable laws or regulations</p>
  <p>Misuse of the platform or attempts to gain unauthorized access</p>
  <p>Engaging in fraudulent or deceptive practices</p>
  <p>
    Any action that may cause harm to Carb Manager, its users, or its partners.
  </p>
          </li>
           <li>
    <p>
      Either party may terminate this Agreement or any Promotion Plan
      immediately on written notice for cause if the other party breaches this
      Agreement and, for breaches which are subject to cure, fails to cure the
      breach within 30 days’ notice. For clarity, any breach by Company of any
      of the representations and warranties set forth in Section 7(b) below is
      not subject to cure.
    </p>
  </li>
    <li>
    <p>
      Either party may terminate this Agreement or any Promotion Plan
      immediately on written notice for cause and without notice if the other
      party breaches Section 6 (Confidentiality) or if a breach is not subject
      to cure. Further, Carb Manager may terminate this Agreement or any
      Promotion Plan immediately for cause and without notice if Company
      breaches any representation or warranty set forth in Section 7(b) below.
    </p>
  </li>
        </ol>
      </li>
       <li>
    <p>
      <strong>Effect of termination.</strong> On termination of the Agreement:
      (1) The parties will not enter into any new Promotion Plan(s); (2) All
      existing Promotion Plan(s) will terminate as of the effective date of
      termination; (3) The Company must cease all use of the Taste Network
      platform; (4) Any outstanding payments will become immediately due; (5)
      Upon termination, Carb Manager has the right to restrict the brand's
      access to their Taste Network account and associated data.
    </p>
  </li>
    <li>
    <p>
      <strong>Survival.</strong> The following Sections will survive
      termination: Section 6 (Confidentiality); Section 7 (Representations and
      Warranties); Section 8 (Indemnification); Section 9 (Limitation of
      liability); and Section 12 (Miscellaneous).
    </p>
  </li>
    </ol>
  </li>
</ol>
<ol start="3" type="a">
 

</ol>
<ol start="6" type="1">
  <li>
    <p><strong>Confidentiality</strong></p>
    <ol type="a">
      <li>
        <p>
          The parties will treat as confidential any information received from
          the other party that is marked as such, or which under the
          circumstances ought reasonably to be treated as confidential (“<strong
            >Confidential Information</strong
          >”). This Agreement and all Customer Information is the Confidential
          Information of Carb Manager. The receiving party will not disclose
          Confidential Information to third parties or use it for purposes other
          than performance of this Agreement without the other party’s written
          consent. The following types of information, however, are not
          confidential information: information that (a) is, or becomes,
          publicly available without a breach of this Agreement; (b) was
          lawfully known to the receiver without an obligation to keep it
          confidential; (c) is independently developed; or (d) is received from
          a third party who can disclose it lawfully and without an obligation
          to keep it confidential.
        </p>
      </li>
      <li>
        <p>
          Company shall comply in all regards with the Data Processing Agreement
          attached hereto as Exhibit B and incorporated herein by reference
          (“<strong>DPA</strong>”).
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><strong>Representations and warranties; Product safety</strong></p>
    <ol type="a">
      <li>
        <p>
          Each party represents and warrants that: (1) It has the right, power
          and authority to enter into this Agreement and perform according to
          its terms; and (2) The performance of its obligations will not breach
          any agreements with a third party.
        </p>
      </li>
      <li>
        <p>Company further represents and warrants that:</p>
        <ol type="1">
          <li>
            <p>
              It shall use Customer Information solely for purposes of
              fulfilling its obligations under this Agreement and solely in
              compliance with the terms of this Agreement, including the DPA.
            </p>
          </li>
          <li>
            <p>
              The Promotion Materials, Trademarks, and Promotion are accurate,
              do not contain material false or misleading statements, and do not
              infringe any third party intellectual property or other
              proprietary right; and
            </p>
          </li>
          <li>
            <p>
              All Products, including all ingredients, packaging and labeling:
              (i) will be in full compliance with all applicable laws,
              regulations, orders, judgments, decrees, or permits (collectively,
              the “<strong>Laws</strong>”); (ii) without limiting the preceding
              subsection (i), will comply with the applicable provisions of the
              Food, Drug and Cosmetic Act, the Food Allergen Labeling and
              Consumer Protection Act, and the Food Safety Modernization Act
              (collectively, the “<strong>Acts</strong>”), all current and
              future amendments to the Acts and all regulations and rules
              implemented under the Acts now and in the future; (iii) as
              applicable, will comply with all applicable Laws and regulations
              of the United States Department of Agriculture applicable to the
              production and certification of organic certified products; (iv)
              will be manufactured, stored and delivered in accordance with
              “Good Manufacturing Practices” as set forth in U.S. Code of
              Federal Regulations, Title 21, Part 110; (v) will be manufactured,
              produced, stored, and delivered in accordance with all applicable
              local health and sanitary ordinances or regulations; (vi) will not
              be adulterated or misbranded within the meaning of the Laws and
              Acts; and (vii) will be permitted pursuant to the Laws and Acts to
              be introduced into interstate commerce.
            </p>
          </li>
          <li>
            <p>
              Company has obtained all permits, licenses and approvals necessary
              to manufacture and supply the Products as required by this
              Agreement, including all required certification standards.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>Product safety, quality and recalls.</strong> If: (i) Carb
          Manager has reasonable cause at any time to believe that any
          Product(s) contain defects or hazards that could create a risk of
          injury to any person or property or that the fulfillment of Product(s)
          by Company or promotion of Product(s) by Carb Manager violates, or
          will violate, any law relating to the sale, labeling, safety or
          transportation or Products, or (ii) Company or a government entity
          initiates or has reasonable cause to initiate removal of Products from
          the stream of commerce (any of the foregoing in (i) or (ii), a
          “<strong>Recall</strong>”), Company shall be responsible for all costs
          associated with any Recall, including, without limitation any costs
          associated with all of Carb Manager’s corrective actions in connection
          with the Recall including, without limitation, timely notice to
          Customers of any Recall, removal of Promotion Materials and cessation
          of Promotional Activities, and disposition of the recalled Products.
          Company will give notice to Carb Manager as soon as practicable in the
          event of a Recall or if Company has reason to believe a Recall may
          occur. In the event of a Recall, Company and Carb Manager will
          cooperate regarding the response, and Company will provide all
          reasonable assistance to Company, at no charge, regarding such
          response.
        </p>
      </li>
    </ol>
  </li>
  <li>
    <p><strong>Indemnification</strong></p>
  </li>
</ol>
<p>
  Each party will indemnify, defend and hold the other party, its affiliates,
  and their respective officers, directors, employees, representatives and
  agents harmless from and against any Claim relating to such other party’s or
  its affiliates’: (1) Breach of any third party intellectual property or other
  proprietary right; (2) Breach of Section 7(b); (3) Willful or intentional
  misconduct; and (4) Carb Manager’s liability arising out of Section 7(c). A
  “<strong>Claim</strong>” means any third party claim, action, or proceeding
  and resulting liabilities, losses, obligations, damages, costs, and expenses
  (including reasonable attorneys’ fees).
</p>
<ol start="9" type="1">
  <li>
    <p><strong>Limitation of liability</strong></p>
  </li>
</ol>
<p>
  Neither party will be liable to the other or its Affiliates for any
  incidental, special, consequential, or indirect damages related to this
  Agreement. This limit includes loss of revenue, profits, business interruption
  or other loss. It applies even if such party knows of the likelihood of such
  damages. The limit does not apply to indemnified claims described in Section
  8, violation of any confidentiality obligations or a party’s infringement of
  the other party's intellectual property rights.
</p>
<ol start="10" type="1">
  <li>
    <p><strong>Insurance</strong></p>
  </li>
</ol>
<p>
  Company shall maintain sufficient insurance coverage to meet its obligations
  created by this Agreement and by law, and will provide proof of coverage upon
  request.
</p>
<ol start="11" type="1">
  <li>
    <p><strong>Press releases and public statements</strong></p>
  </li>
</ol>
<p>
  Unless expressly permitted in the Promotion Plan, Company may not make any
  public statement related to this Agreement without Carb Manager’s prior
  written consent.
</p>
<ol start="12" type="1">
  <li>
    <p><strong>Miscellaneous</strong></p>
    <ol type="a">
      <li>
        <p>
          <strong>Non-exclusivity.</strong> This Agreement is nonexclusive. It
          does not restrict either party from offering the same or similar
          products or services to, or entering into the same or similar
          agreements with, any third party.
        </p>
      </li>
      <li>
        <p>
          <strong>Relationship.</strong> The relationship of the parties is that
          of independent contractors. This Agreement does not create an
          employer-employee, partnership, franchise, agency, or joint venture
          relationship between the parties. Neither party has authority to bind
          the other by its acts or agreements.
        </p>
      </li>
      <li>
        <p>
          <strong>Notices.</strong> All notices and requests are deemed given as
          of the day they are: (1) Deposited in the U.S. mail, postage prepaid,
          certified or registered, return receipt requested; or (2) Sent by
          overnight courier, charges prepaid, with a confirming fax and/or email
          to the address set forth herein.
        </p>
      </li>
      <li>
        <p>
          <strong>Jurisdiction and governing law.</strong> The laws of the State
          of Washington govern this Agreement. If federal jurisdiction exists,
          both parties consent to exclusive jurisdiction and venue in the
          federal courts in King County, Washington. If not, the parties consent
          to the exclusive jurisdiction and venue in the Superior Court of King
          County, Washington.
        </p>
      </li>
      <li>
        <p>
          <strong>Waiver.</strong> A party’s delay or failure to exercise any
          right or remedy will not result in a waiver of that or any other right
          or remedy.
        </p>
      </li>
      <li>
        <p>
          <strong>Severability.</strong> If any court of competent jurisdiction
          determines that any provision of this Agreement is illegal, invalid or
          unenforceable, the remaining provisions will remain in full force and
          effect.
        </p>
      </li>
      <li>
        <p>
          <strong>Attorneys’ fees.</strong> In any dispute relating to this
          Agreement the prevailing party will be entitled to recover reasonable
          attorneys’ fees and costs.
        </p>
      </li>
      <li>
        <p>
          <strong>Assignment.</strong> Either party may assign this Agreement to
          an affiliate. If either party assigns this Agreement, it must notify
          the other in writing. Any other assignment requires prior written
          approval of the other party.
        </p>
      </li>
      <li>
        <p>
          <strong>Entire agreement.</strong> This Agreement (including any
          exhibits) is the entire agreement between the parties regarding its
          subject matter. It replaces all prior agreements, communications and
          representations between the Parties regarding its subject matter.
        </p>
      </li>
      <li>
        <p>
          <strong>Amendment.</strong> This Agreement may be changed only by an
          amendment signed by both parties.
        </p>
      </li>
      <li>
        <p>
          <strong>Counterparts</strong>. The parties may execute this Agreement
          in any number of counterparts. Each counterpart will be deemed an
          original and all counterparts will constitute one agreement binding on
          both parties. Facsimile signatures will be considered binding for all
          purposes.
        </p>
      </li>
    </ol>
  </li>
</ol>
<h2>
  <strong><u>Exhibit A</u></strong>
</h2>
<h3><strong>Promotion Plan</strong></h3>
<p>
  This Promotion Plan (“<strong>Promotion Plan</strong>”), effective on the day
  Company creates their account on tastenetwork.io and agrees to the terms set
  forth in this Agreement (the “<strong>Effective Date</strong>”), is a part of
  and subject to the Promotion Agreement between Wombat Apps LLC d/b/a Carb
  Manager (“<strong>Carb Manager</strong>”) and <strong>Company</strong> (the
  “<strong>Agreement</strong>”). Capitalized terms not defined in this Promotion
  Plan will have the meanings set forth in the Agreement. To the extent of any
  conflict between the terms of this Promotion Plan and the terms of the
  Agreement, the terms of the Agreement will control.
</p>
<ol type="1">
  <li>
    <p><strong>Carb Manager obligations</strong></p>
  </li>
</ol>
<p>Carb Manager agrees to:</p>
<ol type="a">
  <li><p>Promote Company’s participation in the Taste Network Program.</p></li>
  <li>
    <p>Offer Company’s Products to Taste Network Customers free-of-charge.</p>
  </li>
  <li>
    <p>
      Provide to Company the contact information for Customers to enable Company
      to fulfill Customers’ requests for Products.
    </p>
  </li>
  <li>
    <p>
      Use commercially reasonable efforts to obtain Customer feedback about
      Products and share that information in an anonymous and aggregated manner
      with Company.
    </p>
  </li>
  <li>
    <p>
      Support Response Times: Carb Manager agrees to provide support response
      time of at least 24hrs M-F 9am-5pm ET, excluding weekends and holidays
    </p>
  </li>
  <li>
    <p>
      Platform availability: Carb Manager commits to maintaining 99% uptime for
      the Taste Network platform, excluding scheduled maintenance.
    </p>
  </li>
  <li>
    <p>
      Scheduled Maintenance: Carb Manager will provide at least 48 hours notice
      for any scheduled maintenance that may affect platform availability.
    </p>
  </li>
  <li>
    <p>
      Major Changes: For significant changes that might affect core
      functionality on the Taste Network platform, a notice period of 30 days
      will be given to Company.
    </p>
  </li>
  <li>
    <p>
      Feature Deprecation: For deprecation of significant features on the Taste
      Network platform, 60 days notice will be given to allow users time to
      adapt or find alternatives.
    </p>
  </li>
</ol>
<ol start="2" type="1">
  <li>
    <p><strong>Company’s obligations</strong></p>
  </li>
</ol>
<p>Company agrees to:</p>
<ol type="a">
  <li>
    <p>
      Work in good faith with Carb Manager to identify Products for the Taste
      Network Program.
    </p>
  </li>
  <li>
    <p>
      Provide complete and accurate information about all Products, including
      ingredients, allergens, and nutrition information.
    </p>
  </li>
  <li>
    <p>
      Timely and diligently fulfill all Product Orders within 3-5 days of order
      receipt.
    </p>
  </li>
  <li>
    <p>
      Make sure all sample Products shipped to users have an expiration date of
      at least 3 months post-delivery, or reasonable alternative given Product’s
      standard expiry date. If date is expected to be shorter than 3 months,
      Company must inform Carb Manager ahead of listing products on the Taste
      Network platform.
    </p>
  </li>
  <li>
    <p>
      Ensure all products will arrive to consumer in excellent condition, or
      provide appropriate warning if any issues are expected (eg products that
      may melt in hot weather shipped with ice packs, etc.)
    </p>
  </li>
  <li>
    <p>
      If company is unable or chooses not to offer Products shipped directly to
      Customers, due to Product shelf-life, prohibitive shipping costs, or other
      reasons, Company may choose to offer a manufacturer’s coupon for a free
      Product, as agreed with Carb Manager during onboarding or follow-up
      conversations.
    </p>
  </li>
</ol>
<p>
  <strong>SECTION 4 Additional Activities; Costs.</strong> The parties may
  mutually agree to any other Promotion Activities from time to time. Each party
  will perform the Promotion Activities at its cost and expense.
</p>
<p><strong>SECTION 5 Customer Complaints</strong></p>
<p>
  The parties will work with each other to deal with any complaints that may
  arise from the Promotion Activities. Without limiting the foregoing, each
  party will conduct a factual investigation of the complaints arising out of
  its participation in this Promotion Plan at the other’s reasonable request.
  The parties will share all relevant information with each other. Any written
  complaints received by one party that relate to the other’s product will be
  promptly forwarded to that party for response.
</p>
<p><strong>SECTION 7 - Trademarks</strong></p>
<p>
  Company will provide any Trademarks to Carb Manager as it relates to Taste
  Network promotional activities.
</p>
<p><strong>SECTION 8 - Term</strong></p>
<p>
  The term of this Promotion Plan will be the same as that of the Agreement.
</p>
<h2>
  <strong><u>Exhibit B</u></strong>
</h2>
<h3>
  <strong>Data Processing Agreement</strong><br />
</h3>
<p>
  This Data Processing Agreement (“DPA”) is made effective as of the day Company
  creates their account on tastenetwork.io and agrees to the terms set forth in
  this Agreement between Wombat Apps LLC d/b/a Carb Manager, a Hawaii limited
  liability company, with its principal place of business at 8201 164th Ave NE
  #200 Redmond, WA 98052 (“<strong>Carb Manager</strong>”), (“Disclosing
  Party”), and Company (“Receiving Party”).
</p>

  <p><strong>Definitions:</strong></p>
  <p>
    ‘Nonpersonal Data’ means any information that is in no way personally
    identifiable.
  </p>
  <p>
    ‘Personal Data’ means any information relating to an identified or
    identifiable natural person (‘data subject’); an identifiable natural person
    can be identified directly or indirectly by reference to an identifier such
    as a name, an identification number, location data, an online identifier, or
    to one or more factors specific to the physical, physiological, genetic,
    mental, economic, cultural, or social identity of that natural person.
    Personal Data is in many ways the same as Personally Identifiable
    Information (PII). However, Personal Data is broader in scope and covers
    more data.
  </p>
  <p>
    ‘Sensitive Personal Data’ means a consumer’s social security, driver’s
    license, state identification card, or passport number; a consumer’s account
    log-in, financial account, debit card, or credit card number in combination
    with any required security, access code, password, or credentials allowing
    access to an account; a consumer’s exact geolocation; a consumer’s ethnic or
    racial origin, religious or philosophical beliefs, or union membership; the
    contents of a consumer’s mail, text messages, and email unless the business
    is the intended receiver of the communication; a consumer’s genetic data;
    the processing of biometric data to uniquely identify a consumer; personal
    information collected and analyzed about a consumer’s health; sex life or
    sexual orientation.
  </p>
  <p>
    Except for the Disclosing Party; the term ‘Parties’ is an inclusive term
    that means all entities that are bound by this DPA including but not limited
    to the Receiving Party, service providers, contractors, and third parties.
  </p>
  <p><strong>1. Purpose</strong></p>
  <p>
    The purpose of this DPA is to establish the terms and conditions under which
    the Disclosing Party will disclose certain specified Personal Data with the
    Receiving Party for the purpose of Receiving Party fulfilling its
    obligations expressly set forth in the Taste Network Participation
    Agreement. The duration of the processing will be for the duration of the
    Taste Network Participation Agreement between Disclosing Party and Receiving
    party.
  </p>
  <p>
    <strong>2. Personal Data Relating to this Data Processing Agreement</strong>
  </p>
  <p>
    “Personal Data” including but not limited to names, telephone numbers, email
    addresses and shipping addresses of users who have placed orders with the
    Receiving Party, through Taste Network, for purposes of shipping and order
    fulfillment.
  </p>
  <p><strong>3. Compliance with Privacy Laws and Regulations</strong></p>
  <p>
    The Receiving Party agrees to make their best efforts to comply with all
    applicable federal, state, local, provincial, and global laws and
    regulations (if applicable) regarding data privacy and security, including
    but not limited to the California Consumer Privacy Act (CCPA), and the
    California Privacy Rights Act (CPRA). The Receiving Party agrees to notify
    the Disclosing Party if it decides that it can no longer meet its
    obligations under any applicable laws and or regulations. If notified it
    grants the Disclosing Party the right to take appropriate steps to stop and
    correct unauthorized use of Personal or Sensitive Personal Data. If the
    third party, service provider, or contractor hires any other entity to
    assist them with the processing of Personal Data, it must notify the
    Disclosing Party of the hiring and enter into a data processing agreement
    with that other entity that is at least as protective of the Disclosing
    Party and of the disclosed data at this DPA.
  </p>
  <p><strong>4. Use of Personal Data</strong></p>
  <p>
    The Receiving Party must agree to limit the collection, sale, or use of the
    Personal Data disclosed except as necessary to perform the “business
    purpose” for which the Receiving Party was retained as outlined in the Taste
    Network Participation Agreement. The CCPA anticipates that the “business
    purpose” will relate to a covered business’s “operational” needs, such as
    auditing, detecting security incidents, fulfilling orders and transactions,
    processing payments, etc. See Cal. Civ. Code § 1798.140(d). The Receiving
    Party must represent that they have read and understand the CCPA’s
    requirements. See<br />
    https://cppa.ca.gov/regulations/pdf/cppa_act.pdf
  </p>
  <p>
    <strong
      >4.1. Receiving Party Is Prohibited from the Following Use of Personal
      Data</strong
    >
  </p>
  <p>
    Selling Personal Data; retaining, sharing, using, or disclosing Personal
    Data for a commercial purpose other than providing the services for the
    Disclosing Party; and retaining, using, sharing, or disclosing the Personal
    Data outside of this DPA. Receiving Party understands its contractual
    restrictions with the Disclosing Party and will comply with them.
  </p>
  <p>
    <strong>4.2. Limits on Using Personal Data<br /> </strong>The Receiving
    Party will not retain, use, share, disclose, or sell the Personal Data for
    any purpose other than for the specific purpose of performing the services
    outlined in this DPA and the Taste Network Participation Agreement.
  </p>
  <p><strong>4.3. Limiting Personal Data for the Business Purpose</strong></p>
  <p>
    The Receiving Party agrees to limit the use of the Personal Data disclosed
    except as necessary to perform the “business purpose” for which they were
    retained under the Taste Network Participation Agreement. The Receiving
    Party shall not copy, reproduce, or transmit the Personal Data except as
    necessary to fulfill the specified purposes. The Receiving Party shall not
    de-identify, aggregate, or modify the Personal Data unless expressly
    permitted under this DPA. This also prohibits the use of Personal Data for
    cross-context behavioral advertising.
  </p>
  <p><strong>5. Hiring Sub-processors</strong></p>
  <p>
    If the Receiving Party engages a sub-processor or a sub-processor engages a
    sub-processor, the Receiving Party is required to notify the Disclosing
    Party and enter into a contract with the sub-processor containing the above
    requirements.<br />
    <br />
    <strong>6. Confidentiality</strong>
  </p>
  <p>
    The Receiving Party agrees to maintain the confidentiality of all Personal
    Data. The Receiving Party will restrict access to the Personal Data to those
    employees or agents who need access to fulfill the specified purpose and who
    have agreed in writing to keep the Personal Data confidential. The Receiving
    Party agrees to delete or return all Personal Data at the end of the Taste
    Network Participation Agreement or as instructed by the Disclosing Party.
  </p>
  <p>
    Personal Information shall be deemed Confidential Information under the
    Agreement.
  </p>
  <p><strong>7. Personal Data Security</strong></p>
  <p>
    The Receiving Party will implement and maintain industry-standard security
    measures to protect the Personal Data from unauthorized access, destruction,
    use, modification, or disclosure.
  </p>
  <p>
    Specific security measures may include, but are not limited to, encryption,
    access controls, and physical security measures.
  </p>
  <p><strong>8. Data Breach Notification</strong></p>
  <p>
    The Receiving Party will immediately notify the Disclosing Party upon the
    discovery of any data breach or unauthorized access to the Personal Data.
    The notification shall include details of the breach, the type of Personal
    Data involved, the amount of Personal Data involved, and the corrective
    action taken.
  </p>
  <p><strong>9. Audit Rights</strong></p>
  <p>
    The Disclosing Party shall have the right to conduct audits of the Receiving
    Party’s compliance by giving them a 15-day advance notice.
  </p>
  <p><strong>10. Term and Termination</strong></p>
  <p>
    This DPA shall continue until the Taste Network Participation Agreement
    between Disclosing Party and Receiving Party is terminated unless terminated
    earlier by the Disclosing Party upon written notice. Upon termination, the
    Receiving Party shall cease all use of the Personal Data and, at the
    Disclosing Party’s direction, return or destroy all copies of the Personal
    Data.
  </p>
  <p><strong>11. Indemnification</strong></p>
  <p>
    The Receiving Party agrees to indemnify and hold harmless the Disclosing
    Party against any losses, damages, or expenses incurred due to the Receiving
    Party’s breach of this DPA.
  </p>
  <p>
    <u><br /> </u><strong>12. Assistance to Disclosing Party<br /> </strong>The
    Receiving Party will assist the Disclosing Party by appropriate technical
    measures as much as possible with the fulfillment of the Disclosing Party's
    obligation to respond to requests from data subjects wanting to exercise
    their rights under all applicable privacy laws and regulations.
  </p>
  <p>
    <strong>13. Legal Basis for Processing Personal Data<br /> </strong>Our
    legal basis for collecting and processing Personal Data when our products or
    services are purchased or any of the contact forms on our website are filled
    out is based on the necessity for the performance of a contract or to take
    steps to enter into a contract. In some countries, consent may be the only
    legal basis to process Personal Data. In those countries, we will use
    consent as the legal basis for processing Personal Data if we collect
    Personal Data from those countries.
  </p>

`;
