import { CONTRACT_V1 } from '~/constants/contract';
import type { BrandOnboardingConfig } from '@tn/shared';

export const brandOnboardingConfig: Array<BrandOnboardingConfig> = [
  {
    id: 'details',
    dataKey: 'details',
    order: 0,
    title: 'Fill out the information that samplers will use to find your brand',
    type: 'multi-options',
  },

  {
    id: 'products-types',
    dataKey: 'productsType',
    order: 1,
    title: 'What type of products do you carry? ',
    type: 'multi-select-checkbox',
    options: [
      {
        label: 'Snacks (e.g., protein bars, low-calorie chips)',
        value: 'snacks',
      },

      {
        label: 'Dairy and Dairy Alternatives (e.g., plant-based milk)',
        value: 'dairyAndDairyAlternatives',
      },

      {
        label: 'Bakery and Confectionery (e.g., gluten-free, sugar-free)',
        value: 'bakeryAndConfectionery',
      },

      {
        label: 'Meat and Meat Alternatives (e.g., plant-based proteins)',
        value: 'meatAndMeatAlternatives',
      },

      {
        label:
          'Pantry Staples (e.g., chickpea pasta, almond flour, cauliflower rice)',
        value: 'pantryStaples',
      },

      {
        label: 'Other',
        value: 'other',
      },
    ],
  },

  {
    id: 'decisions-types',
    dataKey: 'decisionsTypes',
    order: 2,
    title:
      'What types of decisions would you like Taste Network to help you improve?',
    type: 'multi-select-checkbox',
    options: [
      {
        label: 'Sales and distribution',
        value: 'salesAndDistribution',
      },

      {
        label: 'Marketing and brand awareness',
        value: 'marketing',
      },

      {
        label: 'Consumer insights and feedback',
        value: 'consumerInsights',
      },

      {
        label: 'Product development',
        value: 'productDevelopment',
      },

      {
        label: 'Pricing and profitability',
        value: 'pricingAndProfitability',
      },

      {
        label: 'Supply chain and logistics',
        value: 'supplyChain',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  },

  {
    id: 'sales',
    dataKey: 'salesChannel',
    order: 3,
    title:
      'Which sales channels currently contributes the most to your total sales?',
    type: 'multi-select-checkbox',
    options: [
      {
        label: 'Direct-to-consumer (D2C)',
        value: 'directToConsumer',
      },

      {
        label: 'National retailers',
        value: 'nationalRetailers',
      },

      {
        label: 'Regional stores',
        value: 'regionalStores',
      },

      {
        label: 'Online marketplaces',
        value: 'onlineMarketplaces',
      },

      {
        label: 'Balanced across multiple channels',
        value: 'balancedAcrossChannels',
      },

      {
        label: 'Other',
        value: 'other',
      },
    ],
  },

  {
    id: 'opportunities',
    dataKey: 'opportunitiesAreas',
    order: 4,
    title: 'Which areas do you see as key opportunities for growth?',
    type: 'multi-select-checkbox',
    options: [
      {
        label: 'Increasing brand awareness and optimizing marketing efforts',
        value: 'increaseBrandAwareness',
      },

      {
        label: 'Expanding national or regional retail presence',
        value: 'expandingNationalPresence',
      },

      {
        label: 'Expanding D2C (direct-to-consumer) channels',
        value: 'expandingD2CChannels',
      },

      {
        label:
          'Gathering consumer insights to inform product or marketing decisions',
        value: 'gatherConsumerInsights',
      },

      {
        label: 'Driving product development and innovation',
        value: 'drivingProductDevelopment',
      },

      {
        label: 'Enhancing supply chain, logistics, and sustainability',
        value: 'enhanceSupplyChain',
      },
      {
        label: 'Optimizing pricing strategy and profitability',
        value: 'optimizePricing',
      },
      {
        label: 'Competing with established brands',
        value: 'competeWithBrands',
      },

      {
        label: 'Educating consumers about your products or category',
        value: 'educateConsumers',
      },

      {
        label: 'Managing regulatory compliance and certifications',
        value: 'manageRegulatoryCompliance',
      },

      {
        label: 'Securing financing and capital allocation',
        value: 'secureFinancing',
      },

      {
        label: 'Other',
        value: 'other',
      },
    ],
  },

  {
    id: 'contract',
    dataKey: 'contract',
    order: 5,
    title: 'Please review the Taste Network contract',
    type: 'contract',
    content: CONTRACT_V1,
    options: [
      {
        label:
          'I have read and accept the Taste Network Partnership Agreement terms',
        value: 'contractSigned',
      },
    ],
  },

  {
    id: 'plan',
    dataKey: 'plan',
    order: 6,
    title: 'Choose a plan',
    type: 'plan',
  },
];
